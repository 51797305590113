import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { display, styled } from "@mui/system";
import styles from "./table.module.css";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontFamily: "Unilever Shilling",
        color: "#292929",
        textAlign: "center",
        borderBottom: "none",
        fontSize: "12px",
        fontWeight: 600,
        backgroundColor: "#EAF2FF",
        borderRight: "2px solid #FFF",
        padding: "5px",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: "Unilever Shilling",
        fontSize: "12px",
        borderRight: "2px solid #FFF",
        "&:last-child": {
            borderRight: 0,
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "& td, & th": {
        textAlign: "center",
    },
}));

const ProjectedSupply = ({ data, materialId, plantCode }) => {

    const sortedData = [...(data || [])].sort((a, b) => {
        const dateA = new Date(a?.PodeliveryUpdatedDate);
        const dateB = new Date(b?.PodeliveryUpdatedDate);
        return dateA - dateB;
    });

    return (
        <>
            {sortedData.length > 0 ? (
                <TableContainer className={styles.table}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {[
                                    "Supplier Code",
                                    "Supplier Name",
                                    "Open PO Quantity (in BUoM)",
                                    "PO Delivery Date",
                                ].map((column) => (
                                    <StyledTableCell key={column}>{column}</StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((d, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{d?.VendorCode}</StyledTableCell>
                                    <StyledTableCell>{d?.VendorName}</StyledTableCell>
                                    <StyledTableCell>{d?.OpenPOQuantity.toFixed(1)}</StyledTableCell>
                                    <StyledTableCell>{format(new Date(d?.PodeliveryUpdatedDate), "dd-MMM-yy")}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",
                        fontFamily: "Unilever Shilling",
                        color: "black",
                    }}
                >
                    Open PO is not available for  Material ID:{' '}
                    <Typography component="span" sx={{ fontWeight: "500" }}>
                        {materialId}
                    </Typography>{' '}
                    and  Plant Code:{' '}
                    <Typography component="span" sx={{ fontWeight: "500" }}>
                        {plantCode}
                    </Typography>
                </Typography>
            )}
        </>
    );
};

export default ProjectedSupply;

import React from "react";
import SplitStackChart from "../../../components/splitStackChart/splitStackChart";
import landingConstants from "../../../constants/landing";
import styles from "./styles/exceptionPaneTop.module.css";

const ExceptionChart = (props) => {
  const { criticalityGraph, entityType } = props.content;

  const title =
    props.id === 1
      ? landingConstants.exceptionPaneTitle[0].exceptionByCriticality
      : landingConstants.exceptionPaneTitle[1].exceptionByType;

  const exceptionValue = props.id === 1 ? criticalityGraph : entityType;

  return (
    <>
      <SplitStackChart
        title={title}
        legendContent={exceptionValue}
      ></SplitStackChart>
    </>
  );
};

const ExceptionPaneTop = (exceptionPaneData) => {
  const infoPaneLeftData = exceptionPaneData.info.filter(
    ({ label }) => label === "Total Alert"
  );

  const exceptionValues = [
    { id: 1, data: exceptionPaneData.criticalityGraph },
    { id: 2, data: exceptionPaneData.entityType },
  ];
  return (
    <>
      <div className={styles.exceptionPaneTileTitle}>
        <span className={styles.infoTitle}>{infoPaneLeftData[0].label}</span>
        <span className={styles.exceptionMainContent}>
          {infoPaneLeftData[0].value}
        </span>
      </div>
      <div className={styles.exceptionPaneTop}>
        {exceptionValues.map((item) => (
          <ExceptionChart
            key={item.id}
            id={item.id}
            content={exceptionPaneData}
          />
        ))}
      </div>
    </>
  );
};

export default ExceptionPaneTop;

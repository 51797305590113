import React, { useState, useContext } from "react";
import { Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { convertToLakhs } from "../../utils/convertValueIntoLakhs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CustomTooltip from "../../utils/CustomTooltip";
import { SelectedAlertsContext } from "../../utils/SelectedAlertContext";
import { format } from "date-fns";
import styles from "./addToActionCart.module.css";

const InfoTileCard = ({ infoTileContent, disableActionCartData }) => {
  const headersMap = {
    "Alert ID": "Alert Id",
    "Site Location": "Plant Location",
    "Receiving Site": "Site Code",
    // "Revenue at Risk": "Revenue at risk",
    "Material Description": "Material Description",
    "Quantity At Risk": "Quantity at Risk",
  };

  const dropdownHeadersMap = {
    "Quantity At Risk Recovered": "Quantity At Risk Recovered",
    "Supplying Sites": "Supplying Plant",
    "Delivery Date": "Delivery Date",
  };

  const contentHeader = [...Object.keys(headersMap), ...Object.keys(dropdownHeadersMap)];

  const contentValues = Object.values(headersMap).map((key) =>
    key === "Revenue at risk"
      ? convertToLakhs(infoTileContent[key]) : key === "Quantity at Risk" ? Math.round(infoTileContent[key]) + " " + infoTileContent["Base Unit of Measure"]
      : infoTileContent[key]
  );

  const { selectedCard } = infoTileContent;

  // Calculate the total "Quantity At Risk Recovered"
  const totalQuantityAtRiskRecovered = selectedCard
  .reduce((sum, item) => {
    // Find the relevant data for "Quantity At Risk Recovered"
    const foundDataItem = item.data.find(
      (dataItem) => dataItem.label === "Quantity At Risk Recovered"
    );

    if (foundDataItem) {
      // Calculate the minimum of "Quantity at Risk" and "Quantity At Risk Recovered"
      const minQuantity = Math.min(
        Number(infoTileContent["Quantity at Risk"]) || 0,
        Number(foundDataItem.value) || 0
      );
      return sum + minQuantity; // Add to the running sum
    }

    return sum; // If no relevant data is found, return the current sum
  }, 0)
  .toFixed(0); // Ensure the result is rounded to one decimal place


  // Extract the unique "Planned Delivery Date"
  const plannedDeliveryDate = selectedCard[0]?.data.find((dataItem) => dataItem.label === "Delivery Date")?.value;
  const formattedDeliveryDate = format(new Date(plannedDeliveryDate), "dd-MMM-yy");

  // Extract "Supplying Sites" as a comma-separated list
  const supplyingSites = selectedCard
    .map((item) => item.data.find((dataItem) => dataItem.label === "Supplying Site")?.value)
    .filter(Boolean)
    .join(", ");

  const additionalValues = [
    totalQuantityAtRiskRecovered + " " + infoTileContent["Base Unit of Measure"],
    supplyingSites,
    formattedDeliveryDate,
  ];

  const fullContentValues = [...contentValues, ...additionalValues];
  const { setSelectedAlerts } = useContext(SelectedAlertsContext);

  const [expanded, setExpanded] = useState(false);
  const [items, setItems] = useState(selectedCard);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleRemoveFromCart = () => {
    setSelectedAlerts((prev) => {
      const updatedAlerts = prev.filter(
        (alert) => alert.alertId !== infoTileContent.alertId
      );
      if (updatedAlerts.length === 0) {
        disableActionCartData(false);
      }
      return updatedAlerts;
    });
  };

  const handleRemoveFromScenarioCart = (title) => {
    const updatedItems = items.filter((item) => item.Title !== title);
    setItems(updatedItems);

    setSelectedAlerts((prevOpenRows) =>
      prevOpenRows.map((alert) => {
        if (alert.alertId === infoTileContent.alertId) {
          return { ...alert, selectedCard: updatedItems };
        }
        return alert;
      })
    );

    if (updatedItems.length === 0) {
      handleRemoveFromCart();
    }
  };

  return (
    <div
      className={`${styles.infoTileCardContainer} ${
        expanded ? styles.infoTileCardExpanded : styles.infoTileCardCollapsed
      }`}
    >
      <div className={styles.stackContainer}>
        {contentHeader.map((header, index) => (
          <div className={styles.gridContainer} key={index}>
            <div className={`${styles.gridItem} ${styles.gridItemHeader}`}>
              <Typography className={styles.header}>{header}</Typography>
            </div>
            <div className={styles.gridItemColon}>
              <Typography>:</Typography>
            </div>
            <div className={styles.gridItem}>
              {header === "Material Description" ? (
                <CustomTooltip title={fullContentValues[index]}>
                  <Typography
                    variant="body2"
                    className={styles.infoTileCardDescriptionTooltip}
                  >
                    {fullContentValues[index]}
                  </Typography>
                </CustomTooltip>
              ) : (
                <Typography
                  variant="body2"
                  className={styles.infoTileCardValueText}
                >
                  {fullContentValues[index]}
                </Typography>
              )}

              {index === contentHeader.length - 1 && (
                <div className={styles.infoTileCardActions}>
                  <IconButton onClick={toggleExpand}>
                    {expanded ? (
                      <ExpandLessIcon
                        className={styles.infoTileCardExpandIcon}
                      />
                    ) : (
                      <ExpandMoreIcon
                        className={styles.infoTileCardExpandIcon}
                      />
                    )}
                  </IconButton>
                  <IconButton onClick={handleRemoveFromCart}>
                    <CloseIcon className={styles.infoTileCardCloseIcon} />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        ))}

        {expanded && (
          <div className={styles.infoTileCardExpandedContent}>
            {items.map((item) => (
              <div key={item.Title}>
                <div className={styles.stackRow}>
                  <CheckCircleIcon
                    className={styles.infoTileCardCheckCircleIcon}
                  />
                  <Typography className={styles.infoTileCardItemTitle}>
                    {item.Title}
                  </Typography>
                  <IconButton
                    onClick={() => handleRemoveFromScenarioCart(item.Title)}
                  >
                    <CloseIcon className={styles.infoTileCardCloseIcon} />
                  </IconButton>
                </div>
                <div className={styles.infoTileCardDivider} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoTileCard;

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import styles from "./table.module.css";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontFamily: "Unilever Shilling",
        color: "#292929",
        textAlign: "center",
        borderBottom: "none",
        fontSize: "12px",
        fontWeight: 600,
        backgroundColor: "#EAF2FF",
        borderRight: "2px solid #FFF",
        padding: "5px",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: "Unilever Shilling",
        fontSize: "12px",
        borderRight: "2px solid #FFF",
        "&:last-child": {
            borderRight: 0,
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "& td, & th": {
        textAlign: "center",
    },
}));

const InventoryStatus = ({ data, materialId, plantCode }) => {
    return (
        <>
            {data.length > 0 ? (
                <TableContainer className={styles.table}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {["Plant Code", "Plant Name",
                                    "Base Unit Of Measure",
                                    "Unrestricted Stock", "Stock In Quality Inspection"].map((column) => (
                                        <StyledTableCell key={column}>{column}</StyledTableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((d, index) => (
                                <React.Fragment key={index}>
                                    <StyledTableRow>
                                        <StyledTableCell>{d.PlantCode}</StyledTableCell>
                                        <StyledTableCell>{d.PlantName}</StyledTableCell>
                                        <StyledTableCell>{d.BaseUnitOfMeasure}</StyledTableCell>
                                        <StyledTableCell>{parseFloat(d?.UnrestrictedStock).toFixed(1)}</StyledTableCell>
                                        <StyledTableCell>{parseFloat(d?.StockInQualityInspection).toFixed(1)}</StyledTableCell>
                                    </StyledTableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
                :
                (
                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: "center",
                            fontFamily: "Unilever Shilling",
                            color: "black",
                        }}
                    >
                        Inventory site is not available for Material ID:{' '}
                        <Typography component="span" sx={{ fontWeight: "500" }}>
                            {materialId}
                        </Typography>{' '}
                        and Plant Code:{' '}
                        <Typography component="span" sx={{ fontWeight: "500" }}>
                            {plantCode}
                        </Typography>
                    </Typography>)}

        </>
    );
};

export default InventoryStatus;

import React from "react";
import SplitStackChartAlert from "components/splitStackChart/spiltStackChartAlert";

const ExceptionSection = ({ data }) => {
  const acc = {};
  data.forEach((alert) => {
    const { Criticality, "Revenue at risk": revenue } = alert;
    if (!acc[Criticality]) {
      acc[Criticality] = { count: 0, totalRevenue: 0 };
    }
    acc[Criticality].count += 1;
    acc[Criticality].totalRevenue += revenue;
  });

  return (
    <SplitStackChartAlert data={acc} />
  );
};

export default ExceptionSection;

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import styles from "./table.module.css";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontFamily: "Unilever Shilling",
        color: "#292929",
        textAlign: "center",
        borderBottom: "none",
        fontSize: "12px",
        fontWeight: 600,
        backgroundColor: "#EAF2FF",
        borderRight: "2px solid #FFF",
        padding: "5px",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: "Unilever Shilling",
        fontSize: "12px",
        borderRight: "2px solid #FFF",
        "&:last-child": {
            borderRight: 0,
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "& td, & th": {
        textAlign: "center",
    },
}));

const SupplierLeadTimeTable = ({ data, materialId, plantCode }) => {
    return (
        <>
            {data.length > 0 && data.some(d => {
                const totalTime =
                    (parseInt(d.ProductionTime) || 0) +
                    (parseInt(d.TransitTime) || 0) +
                    (parseInt(d.GRProcessingTime) || 0) +
                    (parseInt(d.AverageInspectionTime) || 0) +
                    (parseInt(d.SafetyTime) || 0);
                return totalTime !== 0;
            }) ? (
                <TableContainer className={styles.table}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Supplier Name</StyledTableCell>
                                <StyledTableCell>Supplier Code</StyledTableCell>
                                <StyledTableCell>Production Lead time</StyledTableCell>
                                <StyledTableCell>Transit time</StyledTableCell>
                                <StyledTableCell>GR Processing Time</StyledTableCell>
                                <StyledTableCell>Average Inspection Time</StyledTableCell>
                                <StyledTableCell>Safety Time</StyledTableCell>
                                <StyledTableCell>Total time horizon</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((d, index) => {
                                const totalTime =
                                    (parseInt(d.ProductionTime) || 0) +
                                    (parseInt(d.TransitTime) || 0) +
                                    (parseInt(d.GRProcessingTime) || 0) +
                                    (parseInt(d.AverageInspectionTime) || 0) +
                                    (parseInt(d.SafetyTime) || 0);

                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell>{d.SupplierName}</StyledTableCell>
                                        <StyledTableCell>{d.SupplierCode}</StyledTableCell>
                                        <StyledTableCell>{d.ProductionTime}</StyledTableCell>
                                        <StyledTableCell>{d.TransitTime}</StyledTableCell>
                                        <StyledTableCell>{d.GRProcessingTime}</StyledTableCell>
                                        <StyledTableCell>{d.AverageInspectionTime}</StyledTableCell>
                                        <StyledTableCell>{d.SafetyTime}</StyledTableCell>
                                        <StyledTableCell>
                                            {totalTime === 0 ? (
                                                <Typography variant="body1" sx={{ textAlign: "center" }}>
                                                    Supplier data is not available for Material ID:{' '}
                                                    <Typography component="span" sx={{ fontWeight: "500" }}>
                                                        {materialId}
                                                    </Typography>{' '}
                                                    and Plant Code:{' '}
                                                    <Typography component="span" sx={{ fontWeight: "500" }}>
                                                        {plantCode}
                                                    </Typography>
                                                </Typography>
                                            ) : (
                                                totalTime
                                            )}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",
                        fontFamily: "Unilever Shilling",
                        color: "black",
                    }}
                >
                    No supplier information available for Material ID:{' '}
                    <Typography component="span" sx={{ fontWeight: "500" }}>
                        {materialId}
                    </Typography>{' '}
                    and Plant Code:{' '}
                    <Typography component="span" sx={{ fontWeight: "500" }}>
                        {plantCode}
                    </Typography>
                </Typography>
            )}
        </>
    );
};

export default SupplierLeadTimeTable;


import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { DataContext } from "../../utils/dataContext/dataContext";
import { SelectedAlertsProvider } from "../../utils/SelectedAlertContext";
import RoutesHistory from "../../components/routesHistory/routesHistory";
import AlertTable from "components/table/alertTable";
import SelectMultipleFilterInput from "../../components/dropdown/MultiSelectCheckbox";
import ExceptionSection from "./exceptionSection";
import AddToActionCart from "../addToActionCart/AddToAction";
// import DateFilter from "../../components/dateRangeCalender/DateRange";
import alertDetailsConstants from "../../constants/alertDetails";
import { alertDetailAPI } from "../../api/demo";
import styles from "./alertDetails.module.css";
import { Box, ListItem, Tab, Tabs } from "@mui/material";
import { convertToCrores } from "utils/convertValueIntoLakhs";
import { Tooltip, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


const AlertDetails = () => {
  const location = useLocation();
  const routesHistoryData = location?.state?.routesHistoryData;

  const [alertDetailsData, setAlertDetailsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAnyScorecardSelected, setIsAnyScorecardSelected] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [disableActionCart, setDisableActionCart] = useState(false);
  const [revenueDetails, setRevenueDetails] = useState(0);

  const initialDropdownState = useMemo(() => ({
    "Alert Type": [],
    "Site Code": [],
    "Criticality": [],
    "Material ID": [],
    "Material Type": [],
    "Material Description": [],
    "Recommendation": [],
    "Week": [],
    "Alert Status": [],
    "Site Cluster": [],
    // "CBU": []
  }), []);

  const [getDropdown, setDropdown] = useState(initialDropdownState);
  const [selectedValues, setSelectedValues] = useState(initialDropdownState);

  /**
   * Fetch alert details data
   */
  const fetchData = useCallback(async () => {
    try {
      const data = await alertDetailAPI();
      setAlertDetailsData(data);
      setFilteredData(data?.alertDetails || []);
      const totalRevenue = data?.alertDetails.reduce((accumulator, item) => {
        const revenue = parseFloat(item["Revenue at risk"]) || 0;
        return accumulator + revenue;
      }, 0);
      
      setRevenueDetails(totalRevenue);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching alert details:", error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!alertDetailsData?.alertDetails) return;

    const updatedFilteredData = alertDetailsData.alertDetails.filter((item) =>
      Object.keys(selectedValues).every((filterKey) => {
        if (!selectedValues[filterKey].length) return true; // Skip filter if no values are selected

        if (filterKey === "Week") {
          const alertIdPrefix = item["Alert Id"].split("-").slice(0, 2).join("-");
          return selectedValues[filterKey].includes(alertIdPrefix);
        }

        if (filterKey === "Recommendation") {
          return selectedValues[filterKey].includes(item.isRecommended ? "Yes" : "No");
        }

        // General filter logic for other fields
        return selectedValues[filterKey].includes(item[filterKey]);
      })
    );

    setFilteredData(updatedFilteredData);
  }, [selectedValues, alertDetailsData]);


  useEffect(() => {
    if (!filteredData.length) return;
  
    const allOptions = Object.keys(initialDropdownState).reduce((acc, key) => {
      acc[key] = [
        ...new Set(filteredData.map((item) =>
          key === "Week"
            ? item["Alert Id"].split("-").slice(0, 2).join("-")
            : key === "Recommendation"
            ? item.isRecommended ? "Yes" : "No"
            : item[key]
        )),
      ];
      return acc;
    }, {});
  
    const sortedWeekOptions = [...allOptions.Week].sort((a, b) => {
      const [fyA, weekA] = a.split("-");
      const [fyB, weekB] = b.split("-");
      return fyA === fyB
        ? parseInt(weekA) - parseInt(weekB)
        : fyA.localeCompare(fyB);
    });
    allOptions.Week = sortedWeekOptions;
  
    // Explicitly set "Alert Status" options
    // allOptions["Alert Status"] = ["Open", "Overdue"];
  
    const updatedDropdowns = Object.keys(initialDropdownState).reduce((acc, key) => {
      if (selectedValues[key]?.length) {
        acc[key] = getDropdown[key];
      } else {
        acc[key] = filteredData
          .filter((item) =>
            Object.keys(selectedValues).every((filterKey) => {
              if (!selectedValues[filterKey].length) return true;
              if (filterKey === "Week") {
                const alertIdPrefix = item["Alert Id"].split("-").slice(0, 2).join("-");
                return (
                  selectedValues[filterKey].includes(item[filterKey]) ||
                  selectedValues[filterKey].includes(alertIdPrefix)
                );
              }
              if (filterKey === "Recommendation") {
                return selectedValues[filterKey].includes(item.isRecommended ? "Yes" : "No");
              }
              return selectedValues[filterKey].includes(item[filterKey]);
            })
          )
          .map((item) =>
            key === "Week"
              ? item["Alert Id"].split("-").slice(0, 2).join("-")
              : key === "Recommendation"
              ? item.isRecommended ? "Yes" : "No"
              : item[key]
          )
          .filter((value, index, self) => self.indexOf(value) === index);
      }
      return acc;
    }, {});
  
    // Ensure "Alert Status" always includes "Open" and "Overdue"
    // if (updatedDropdowns["Alert Status"]) {
    //   updatedDropdowns["Alert Status"] = ["Open", "Overdue"];
    // }
  
    setDropdown(updatedDropdowns);
  }, [filteredData, selectedValues]);

  /**
   * Handle dropdown value change
   */
  const handleMultiSelectChange = (label, values) => {
    setSelectedValues((prev) => ({
      ...prev,
      [label]: values
    }));
  };

  /**
   * Reset filters
   */
  const resetFilters = () => {
    const restObj = {
      "Alert Type": [],
      "Site Code": [],
      "Criticality": [],
      "Material ID": [],
      "Material Type": [],
      "Material Description": [],
      "Recommendation": [],
      "Week": [],
      "Alert Status": [],
      "Site Cluster": [],
    };
    setSelectedValues(restObj);
    setResetKey((prev) => prev + 1);
  };

  /**
   * Handle add to cart click
   */
  const handleAddToCartClick = () => {
    if (isAnyScorecardSelected) setIsDrawerOpen(true);
  };

  const handleSelectionChange = (selectedCount) => {
    setIsAnyScorecardSelected(selectedCount > 0);
  };

  const handleDisableActionCart = (data) => {
    setDisableActionCart(data);
    if (data === false) setIsAnyScorecardSelected(false);
  };

  return (
    <DataContext.Provider value={{ routesHistoryData }}>
      <SelectedAlertsProvider>
        <div className={styles.alertDetailsContentContainer}>
          <div className={styles.columnStack}>
            <div className={`${styles.rowStack} ${styles.routesHistoryContainer}`}>
              <RoutesHistory content={routesHistoryData} />
              <div style={{ display: 'flex', gap: '10px' }}>
                    <Button disabled={!Object.values(selectedValues).some((val) => val.length > 0)} onClick={resetFilters} className={styles.actionBtn}>
                      {alertDetailsConstants.resetFilterTitle}
                    </Button>
                    <Button disabled={!isAnyScorecardSelected || disableActionCart} onClick={handleAddToCartClick} className={styles.actionBtn}>
                      {alertDetailsConstants.addToActionCartTitle}
                      <ShoppingCartOutlinedIcon className={styles.shopIcon} />
                    </Button>
                  </div>
            </div>
            <div className={styles.insights} style={{display: 'flex'}}>
              <div style={{width: "15%", backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column", backgroundColor: "#1f36c7bf", color: "white", borderRadius: "5px 0 0 5px"}}>
                <span style={{ fontSize: "16px", display: "flex", alignItems: 'center'}}>Revenue Potential
                <Tooltip title="Revenue at risk at a CBU level that can be recovered">
                        <IconButton size="small" color="white">
                            <InfoOutlinedIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </span>
                <br />
                <span style={{ fontSize: "20px", fontWeight: 600}}>{convertToCrores(revenueDetails)}</span>
              </div>
              <ExceptionSection exceptionOverview={alertDetailsData?.exceptionOverview} data={filteredData} />
            </div>
            <div className={styles.alertDetails}>
              {loading ? (
                <div className={styles.loaderContainer}><CircularProgress /></div>
              ) : (
                <>
                  <div className={styles.filters}>
                    {Object.keys(getDropdown).map((key) => (
                      <ListItem key={key} className={styles.listCustomClass}>
                        <SelectMultipleFilterInput
                          key={`${key}-${resetKey}`}
                          label={key}
                          options={getDropdown[key]}
                          selected={selectedValues[key]}
                          onChange={(values) => handleMultiSelectChange(key, values)}
                        />
                      </ListItem>
                    ))}
                  </div>
                  <AlertTable data={filteredData} onScorecardClick={setIsAnyScorecardSelected} onSelectionChange={handleSelectionChange} />
                </>
              )}
            </div>
          </div>
        </div>
        <AddToActionCart anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} disableActionCartData={handleDisableActionCart} />
      </SelectedAlertsProvider>
    </DataContext.Provider>
  );
};

export default AlertDetails;

import React, { useState, useContext, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Button,
  Checkbox,
  TablePagination,
  Snackbar
} from "@mui/material";
import { styled } from "@mui/system";
import CustomTooltip from "../../utils/CustomTooltip";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { SelectedAlertsContext } from "../../utils/SelectedAlertContext";
import { convertToLakhs } from "../../utils/convertValueIntoLakhs";
import Resolve from "./../../pages/alertDeatils1/component/resolve";
import styles from "./table.module.css";
import DemandPopup from "components/popup/demandPopup";
import { format } from 'date-fns';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Unilever Shilling",
    color: "#292929",
    textAlign: "center",
    borderBottom: "none",
    fontSize: "12px",
    fontWeight: 600,
    backgroundColor: "#EAF2FF",
    borderRight: "2px solid #FFF",
    padding: "5px",
    '@media (max-width: 1500px)': {
      fontSize: "10px",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Unilever Shilling",
    fontSize: "12px",
    borderRight: "2px solid #FFF",
    "&:last-child": {
      borderRight: 0,
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    '@media (max-width: 1500px)': {
      fontSize: "10px",
    },
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "& td, & th": {
    textAlign: "center",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const AlertTable = ({ data, onScorecardClick, onSelectionChange }) => {
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState("Alert Id");
  const { selectedAlerts, setSelectedAlerts } = useContext(SelectedAlertsContext);
  const [openRows, setOpenRows] = useState({});
  const [open, setOpen] = useState(false);
  const [dialogType, setDialogType] = useState({ "type": "", alertId: "", materialId: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    setPage(0);
  }, [data])

  const handleClose = () => {
    setOpen(false);
  };

  const openKeyInsgits = (type, alertId, materialId, plantCode, week) => {
    const weekNumber = week.replace(/\D/g, "");
    setDialogType({ type: type, alertId: alertId, materialId: materialId, plantCode, week: weekNumber });
    setOpen(true);
  };

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(direction);
    setSortColumn(column);
  };

  const sortedData = [...data]?.sort((a, b) => {
    if (sortColumn === "Rev. Opportunity Loss") {
      const valueA = a["Revenue at risk"];
      const valueB = b["Revenue at risk"];
      return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
    } else if (sortColumn === 'Qty at Risk') {
      const valueA = a["Quantity at Risk"];
      const valueB = b["Quantity at Risk"];
      return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
    } else if (sortColumn === "Action by") {
      const dateA = new Date(a["Action by (Date)"]);
      const dateB = new Date(b["Action by (Date)"]);
      return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
    } else {
      if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
      return 0;
    }
  });


  const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleCheckboxChange = (alertId) => {
    const alert = data?.find((a) => a["Alert Id"] === alertId);

    const hasSelectedScorecard = selectedAlerts.some(
      (a) => a["Alert Id"] === alertId && a.selectedCard && a.selectedCard.length > 0
    );

    if (!alert || !hasSelectedScorecard) {
      setSnackbarOpen(true);
      return;
    }

    setSelectedAlerts((prevSelected) => {
      const alreadySelected = prevSelected.some((a) => a["Alert Id"] === alertId);
      const updatedSelected = alreadySelected
        ? prevSelected.filter((a) => a["Alert Id"] !== alertId)
        : [...prevSelected, alert];

      onSelectionChange(updatedSelected.length > 0);
      return updatedSelected;
    });
  };

  const getCriticalityColor = (criticality) => {
    switch (criticality.toLowerCase()) {
      case "critical":
        return "rgb(240 77 78 / 76%)";
      case "high":
        return "#FF9998";
      case "medium":
        return "#FFC000";
      case "low":
        return "#FFEC9A";
      default:
        return "#87CEEB";
    }
  };

  const handleExpandCollapse = (alertId) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [alertId]: !prevOpenRows[alertId],
    }));
  };

  const handleCardSelect = (isCardSelected, alert) => {
    setSelectedAlerts((prevSelected) => {
      const alertIndex = prevSelected?.findIndex(
        (a) => a["Alert Id"] === alert["Alert Id"]
      );
      let updatedSelectedAlerts;

      if (alertIndex > -1) {
        const updatedAlert = {
          ...prevSelected[alertIndex],
          selectedCard: isCardSelected,
        };
        updatedSelectedAlerts = [...prevSelected];
        updatedSelectedAlerts[alertIndex] = updatedAlert;
      } else {
        updatedSelectedAlerts = [
          ...prevSelected,
          { ...alert, selectedCard: isCardSelected },
        ];
      }

      if (
        updatedSelectedAlerts.some((alert) => alert["selectedCard"].length > 0)
      ) {
        onScorecardClick(true);
      } else {
        onScorecardClick(false);
      }
      return updatedSelectedAlerts;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  return (
    <>
      <TableContainer className={styles.table} style={{ height: 'calc(100vh - 340px)' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {/* <StyledTableCell></StyledTableCell> */}
              {[
                "Alert Id",
                "Root Cause",
                "Material ID",
                // "Material Type",
                "Material Description",
                "Site Code",
                // "Rev. Opportunity Loss",
                "Qty at Risk",
                "Alert Status",
                // "Action by",
                "Criticality",
              ]?.map((column) => (
                <StyledTableCell key={column} onClick={() => handleSort(column)}>
                  <div className={styles.sortColumn}>
                    {column}
                    {sortColumn === column &&
                      (sortDirection === "asc" ? (
                        <ArrowUpwardIcon className={styles.sortIcon} />
                      ) : (
                        <ArrowDownwardIcon className={styles.sortIcon} />
                      ))}
                  </div>
                </StyledTableCell>
              ))}
              <StyledTableCell>Insights</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData?.map((alert, index) => (
              <React.Fragment key={alert["Alert Id"] + index}>
                <StyledTableRow key={alert["Alert Id"] + index + 1}>
                  {/* <StyledTableCell>
                    <Checkbox
                      color="primary"
                      checked={selectedAlerts?.some(
                        (a) =>
                          a["Alert Id"] === alert["Alert Id"] &&
                          a["selectedCard"] &&
                          a["selectedCard"].length > 0
                      )}
                      onChange={() => handleCheckboxChange(alert["Alert Id"])}
                      sx={{
                        padding: 0,
                        "& .MuiSvgIcon-root": {
                          fontSize: "16px",
                          "@media (max-width: 1500px)": {
                            fontSize: "12px",
                          },
                        },
                      }}
                    />
                  </StyledTableCell> */}
                  <StyledTableCell>{alert["Alert Id"]}</StyledTableCell>
                  <StyledTableCell className={styles.ellipsisText}>
                    <CustomTooltip title={alert["Root Cause"]}><span>{alert["Root Cause"]}</span></CustomTooltip>
                  </StyledTableCell>
                  <StyledTableCell>{alert["Material ID"]}</StyledTableCell>
                  {/* <StyledTableCell>{alert["Material_Type"]}</StyledTableCell> */}
                  <StyledTableCell className={styles.ellipsisText}>
                    <CustomTooltip title={alert["Material Description"]}><span>{alert["Material Description"]}</span></CustomTooltip>
                  </StyledTableCell>
                  <StyledTableCell>{alert["Site Code"]}</StyledTableCell>
                  {/* <StyledTableCell>{convertToLakhs(alert["Revenue at risk"])}</StyledTableCell> */}
                  <StyledTableCell>{alert["Quantity at Risk"] === null ? 0 : alert["Quantity at Risk"].toFixed(0) + ' ' + alert["Base Unit of Measure"]}</StyledTableCell>
                  <StyledTableCell>
                    {/* {alert["Alert Status"] === "Overdue" */}
                    {/* ? `${alert["Alert Status"]} (${Math.abs(alert["Days To Action"])} days)` */}
                    {/* :  */}
                    {alert["Alert Status"]}
                  </StyledTableCell>
                  {/* <StyledTableCell>{format(new Date(alert["Action by (Date)"]), 'dd-MMM-yy')}</StyledTableCell> */}
                  <StyledTableCell style={{ backgroundColor: getCriticalityColor(alert.Criticality) }}>{alert.Criticality}</StyledTableCell>
                  <StyledTableCell className={styles.insightBtn} onClick={() => openKeyInsgits("Insights", alert["Alert Id"], alert["Material ID"], alert["Site Code"], alert["Week"])}>View</StyledTableCell>
                  <StyledTableCell>
                    <Button className={styles.resolvedBtn} disabled={alert["isRecommended"] === false} onClick={() => handleExpandCollapse(alert["Alert Id"])}>
                      Resolve{" "}
                      {openRows[alert["Alert Id"]] ? (<KeyboardArrowUpIcon className={styles.arrowBtn} />) : (<KeyboardArrowDownIcon className={styles.arrowBtn} />)}
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={styles.collapseSection} colSpan={14} padding="0">
                    <Collapse in={openRows[alert["Alert Id"]]} timeout="auto" unmountOnExit>
                      <div style={{ padding: "10px" }} >
                        <Resolve alert={alert} onCardSelect={(isCardSelected) => handleCardSelect(isCardSelected, alert)} />
                      </div>
                    </Collapse>
                  </StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[]}
        sx={{
          padding: 0,
          "& .MuiTablePagination-toolbar": {
            minHeight: "20px",
            fontSize: "12px",
            "@media (max-width: 1500px)": {
              fontSize: "12px",
            },
          },
          "& .MuiTablePagination-select": {
            fontSize: "12px"
          },
          "& .MuiTablePagination-selectLabel": {
            fontSize: "12px"
          },
          "& .MuiTablePagination-displayedRows": {
            fontSize: "12px"
          },
          "& .MuiTablePagination-actions": {
            marginLeft: "10px"
          },
          "& .MuiIconButton-root": {
            fontSize: "14px"
          },
          "& .MuiSvgIcon-root": {
            height: "14px",
            width: "14px",
            fontSize: "14px"
          },
        }}
      />

      <DemandPopup open={open} handleClose={handleClose} type={dialogType.type} alertId={dialogType.alertId} materialId={dialogType.materialId} plantCode={dialogType.plantCode} week={dialogType.week} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Please select a scorecard before selecting an alert."
      />
    </>
  );
};

export default AlertTable;

import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { convertToCrores } from "../../utils/convertValueIntoLakhs";
import styles from "./splitStackChart.module.css";

const colorMap = {
    Critical: "rgb(240 77 78 / 76%)",
    High: "#FF9998",
    Medium: "#FFC000",
    Low: "#FFEC9A",
};

const ExceptionBar = ({ label, value, totalSum, totalHeight, type }) => {
    const widthPercentage = (value / totalSum) * 100;

    return (
        <div
            className={styles.exceptionBar}
            style={{
                height: totalHeight + "vh",
                width: label !== "Low" ? widthPercentage + "%" : "",
                backgroundColor: colorMap[label]
            }}
            title={`${label}: ${type?.includes("Revenue") ? convertToCrores(value) : value} (${widthPercentage.toFixed(1)}%)`}
        >
            {widthPercentage > 5 && (
                <span className={styles.exceptionBarTitle}>
                    {value !== 0 && type?.includes("Revenue") ? convertToCrores(value) : value}
                </span>
            )}
        </div>
    );
};

const SplitStackChart = ({ data }) => {
    const criticalityData = Object.keys(data).map((key) => ({
        label: key,
        count: data[key].count,
        revenue: data[key].totalRevenue,
    }));

    const filteredData = criticalityData.filter((item) => item.label !== "Low");

    const p2Alert = criticalityData.filter((item) => item.label === "Low");

    const totalCount = filteredData.reduce(
        (accumulator, item) => accumulator + item.count,
        0
    );

    // const totalRevenue = criticalityData.reduce(
    //     (accumulator, item) => accumulator + item.revenue,
    //     0
    // );

    const order = ["Critical", "High", "Medium", "Low"];

    // const sortedData = criticalityData.sort(
    //     (a, b) => order.indexOf(a.label) - order.indexOf(b.label)
    // );

    const sortedDataExpection = filteredData.sort(
        (a, b) => order.indexOf(a.label) - order.indexOf(b.label)
    );

    return (
        <div style={{ display: "flex", width: "100%", backgroundColor: "white", border: "1px solid #5164d5",
            backgroundColor: "white", borderRadius: "0 5px 5px 0" }}>
            <div className={styles.columnStackAlert}>
                <span className={styles.title}>
                    Total P1 Alert: <b>{totalCount}</b>
                    <Tooltip title="Alert raised when there is a direct demand impact ( Demand vs Opening Stock)">
                        <IconButton size="small">
                            <InfoOutlinedIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </span>
                <div className={styles.exceptionOverviewBarContainer}>
                    {sortedDataExpection.map((item) => (
                        <ExceptionBar
                            key={`${item.label}-count`}
                            label={item.label}
                            value={item.count}
                            totalSum={totalCount}
                            totalHeight={4.5}
                        />
                    ))}
                </div>
                <div className={styles.legendsContainer}>
                    {sortedDataExpection.map((item) => (
                        <div
                            key={`${item.label}-legend-count`}
                            className={styles.legendItem}
                        >
                            <span
                                className={styles.legendColorBox}
                                style={{ backgroundColor: colorMap[item.label] }}
                            ></span>
                            <span className={styles.legendLabel}>{item.label}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.expectionClass}>
                <span className={styles.title}>
                    Total P2 Alert: <b>{p2Alert[0]?.count || 0}</b>
                    <Tooltip title="Alert raised when there is an impact on safety norm ( Safety Norm vs Opening Stock)">
                        <IconButton size="small">
                            <InfoOutlinedIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </span>
                {
                    <div className={p2Alert[0]?.count ? `${styles.p2Alert}` : ""}>
                        {p2Alert[0]?.count || 0}
                    </div>
                }
            </div>

            {/* <div className={styles.columnStackAlert}>
                <span className={styles.title}>
                    Total Revenue Opportunity Loss: <b>₹{convertToCrores(totalRevenue)}</b>
                </span>
                <div className={styles.exceptionOverviewBarContainer}>
                    {sortedData.map((item) => (
                        <ExceptionBar
                            key={`${item.label}-revenue`}
                            label={item.label}
                            value={item.revenue}
                            totalSum={totalRevenue}
                            totalHeight={4.5}
                            type="Revenue"
                        />
                    ))}
                </div>
                <div className={styles.legendsContainer}>
                    {sortedData.map((item) => (
                        <div
                            key={`${item.label}-legend-revenue`}
                            className={styles.legendItem}
                        >
                            <span
                                className={styles.legendColorBox}
                                style={{ backgroundColor: colorMap[item.label] }}
                            ></span>
                            <span className={styles.legendLabel}>{item.label}</span>
                        </div>
                    ))}
                </div>
            </div> */}
        </div>
    );
};

export default SplitStackChart;
